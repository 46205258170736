import domReady from '@wordpress/dom-ready';

domReady(() => {
  const alertsBar = document.getElementById('alerts-bar');
  let closeBtn = null;

  if (alertsBar) {
    closeBtn = alertsBar.querySelector('.closeBtn');

    closeBtn.addEventListener('click', function () {
      document.body.classList.remove('has-alerts-bar');
      document.cookie = 'remove_alerts_bar=true';
      alertsBar.classList.remove('visible');
    });
  }
});
