import domReady from '@wordpress/dom-ready';

domReady(() => {
  const button = document.querySelector('.clavardage');

  if (button) {
    button.addEventListener('click', function (e) {
      e.preventDefault();

      const callUsSelector = document.querySelector('call-us-selector');
      const callUsSelectorShadow = callUsSelector.shadowRoot;
      const callUs = callUsSelectorShadow.getElementById('wp-live-chat-by-3CX');
      const callUsShadow = callUs.shadowRoot;

      callUsShadow.getElementById('wplc-chat-button').click();
    });
  }
});
