import $ from 'jquery';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  /**
   * Init older accordion type.
   */
  const initVtxAccordion = () => {
    const $accordion = $('.vtx-accordion');

    if (!$accordion.length) {
      // BAIL. No accodion.
      return;
    }

    $accordion.each(function () {
      const $thisAccordion = $(this);
      if ($thisAccordion.hasClass('initialized')) {
        // BAIL. Already initialized.
        return;
      }

      $thisAccordion.addClass('initialized');

      $thisAccordion.on('click', '.accordion-item-toggle', function (event) {
        event.preventDefault();

        const $toggle = $(this);
        const $accordionContent = $('#' + $toggle.attr('aria-controls'));

        if ($toggle.hasClass('opened')) {
          $toggle.attr('aria-expanded', 'false').removeClass('opened');

          $accordionContent.removeClass('opened').stop().slideUp('fast');
        } else {
          if (!$toggle.data('allow-multiple')) {
            const $allOpenedEl = $accordionContent
              .parents('.vtx-accordion')
              .find('.opened');

            $allOpenedEl.removeClass('opened');
            $allOpenedEl.filter('.accordion-item__content').slideUp('fast');
          }

          $toggle.attr('aria-expanded', 'true').addClass('opened');

          $accordionContent.addClass('opened').stop().slideDown('fast');
        }
      });
    });
  };

  if ($('.accordion').length > 0) {
    $('.accordion__accordion.open > .accordion__sub-rows').slideDown();

    $(document).on('click', '.accordion-toggle', function () {
      const accordionToggle = $(this);
      accordionToggle.parent('.accordion__accordion').toggleClass('open');
      if (accordionToggle.parent('.accordion__accordion').hasClass('open')) {
        accordionToggle.next('.accordion__sub-rows').slideDown();
        accordionToggle.attr('aria-expanded', true);
      } else {
        accordionToggle.next('.accordion__sub-rows').slideUp();
        accordionToggle.attr('aria-expanded', false);
      }
    });
  }

  initVtxAccordion();

  $(document).on('gform_page_loaded gform_confirmation_loaded', function () {
    initVtxAccordion();
  });
});
