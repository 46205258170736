import printJS from 'print-js';

const openPrintPrompt = () => {
  printJS({
    printable: 'printable-content',
    type: 'html',
    targetStyles: ['*'],
  });
};

const getPrintableContentNode = () => {
  let printable = document.getElementById('printable-content');
  if (printable) {
    // BAIL. Got it.
    return printable;
  }

  const printableWrapper = document.createElement('script');
  printableWrapper.id = 'printable-content-wrapper';
  printableWrapper.setAttribute('type', 'text/template');

  printable = document.createElement('div');
  printable.id = 'printable-content';
  printable.classList.add('entry-content');

  printableWrapper.append(printable);
  document.body.append(printableWrapper);

  return printable;
};

/**
 * Get all `.printable-content` elements and concatenante their HTML.
 * Also, inside the selected nodes, it checks for `.printable-content-start` and `.printable-content-end`.
 * If found, it will remove all HTML before `.printable-content-start` and all HTML after and including `.printable-content-end`.
 *
 * (!) Using `innerHTML`. The container isn't part of the printed content. (!)
 * (?) Preparing the content reset it first wich means it permits the content to be dynamic. (?)
 *
 * @return {boolean}  TRUE if any `.printable-content` element exists, FALSE otherwise.
 */
const preparePrintableContent = () => {
  const printableNodes = document.querySelectorAll('.printable-content');
  if (!printableNodes.length) {
    // No exclicitly printable content. Just print the page.
    return false;
  }

  let html = '';
  printableNodes.forEach((node) => {
    // Check inside content if there is a start marker (.printable-content-start).
    let nodeHtml = node.innerHTML;

    if (-1 !== nodeHtml.indexOf('printable-content-start')) {
      const match = nodeHtml.match(
        /(<[a-z]+ [^>]*class="[^"]*printable-content-start)/
      );
      if (null !== match) {
        nodeHtml = nodeHtml.substring(match.index);
      }
    }

    // Check inside content if there is an end marker (.printable-content-end).
    // The node with that class won't be part of the content.
    if (-1 !== nodeHtml.indexOf('printable-content-end')) {
      const match = nodeHtml.match(
        /(<[a-z]+ [^>]*class="[^"]*printable-content-end)/
      );
      if (null !== match) {
        nodeHtml = nodeHtml.substring(0, match.index);
      }
    }

    html += nodeHtml;
  });

  // Remove interactive elements.
  html = html.replace(/<button[^>]*>.*<\/button>/, '');

  const printableContentNode = getPrintableContentNode();
  printableContentNode.innerHTML = html;

  // There was content.
  return true;
};

export const printContent = () => {
  const hasContent = preparePrintableContent();
  if (hasContent) {
    openPrintPrompt();
  } else {
    window.print();
  }
};
