import domReady from '@wordpress/dom-ready';
import Likely from 'ilyabirman-likely';
import { printContent } from './print-helpers';
import { _x } from '@wordpress/i18n';
import $ from 'jquery';

domReady(() => {
  const getDefaultShareTitle = () => {
    const titleNode = document.querySelector('h1');
    if (titleNode) {
      return titleNode.innerText;
    }

    return _x('Shared content', 'default share title', 'vtx');
  };

  const initMediaActionBtns = () => {
    Likely.initiate();

    const btnPrintContent = document.querySelectorAll(
      '.mediaActionPrintContent'
    );
    btnPrintContent.forEach((btn) => {
      if (btn.classList.contains('initialized')) {
        // BAIL. Already initialized.
        return;
      }

      btn.classList.add('initialized');

      btn.addEventListener('click', (event) => {
        event.preventDefault();
        printContent();
      });
    });

    const btnShare = document.querySelectorAll('.mediaActionShare');
    btnShare.forEach((btn) => {
      if (btn.classList.contains('initialized')) {
        // BAIL. Already initialized.
        return;
      }

      btn.classList.add('initialized');

      btn.addEventListener('click', async (event) => {
        event.preventDefault();

        const { url, title, text } = btn.dataset;
        const data = {
          url: url ? url : window.location.href,
          title: title ? title : getDefaultShareTitle(),
          text: text ? text : '',
        };

        try {
          await navigator.share(data);
        } catch (error) {
          console.warn(error);
        }
      });
    });
  };

  ///// INIT
  initMediaActionBtns();

  $(document).on('gform_page_loaded gform_confirmation_loaded', function () {
    initMediaActionBtns();
  });
});
