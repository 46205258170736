import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
import domReady from '@wordpress/dom-ready';

let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      console.log(fileUpload);
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

function initGFSelects() {
  const selects = document.querySelectorAll('.gfield_select');

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {});
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  initGFSelects();
});

// ON READY
domReady(() => {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    initGFSelects();
  }
});
