window.addEventListener('load', function () {
  const FWP = window.FWP;
  if (FWP) {
    if (FWP.facets.categories) {
      $('html, body').animate(
        {
          scrollTop: $('.facetwp-template').offset().top - 300,
        },
        500
      );
    }
  }
});
