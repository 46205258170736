//sticky nav
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const lastScrollTop = 80;

  document.addEventListener('scroll', function () {
    const st = window.scrollY;

    if (st > lastScrollTop && 0 <= lastScrollTop) {
      document.body.classList.add('nav-sticky');
    } else {
      document.body.classList.remove('nav-sticky');
    }
  });
});
